<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex md12>
                <material-card title="Products">

                    <template :slot="'header_actions'">
                        <v-btn rounded fab small @click="showUploadModal()">
                            <v-icon>mdi-file-upload</v-icon>
                        </v-btn>
                    </template>

                    <v-row class="mx-2" justify="center">
                        <v-text-field class="mr-2"
                                v-model="filter.name"
                                label="Name"
                                style="max-width: 300px;"
                        ></v-text-field>


                        <v-text-field class="mr-2"
                                v-model="filter.item_number"
                                label="Item Number"
                                      style="max-width: 300px;"
                        ></v-text-field>

                        <v-select
                                v-model="filter.factory"
                                :items="factories"
                                item-value="id"
                                item-text="name"
                                label="Factories"
                        ></v-select>

                        <v-btn fab small color="primary" class="mx-2"
                                @click="filter_results(filter)">
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>

                    </v-row>

                    <v-data-table :headers="headers"
                                  :items="products"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>

                        <template v-slot:item.actions="{ item }">
                            <v-btn small rounded text fab
                                   @click="edit_product(item.id)"
                                   v-tooltip="'Edit Product'">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.created_at="{ item }">
                            {{ format_date(item.created_at) }}
                        </template>

                        <template v-slot:item.updated_at="{ item }">
                            {{ format_date(item.updated_at) }}
                        </template>

                        <template v-slot:item.fact_details="{ item }">
                            <v-btn
                                    color="red lighten-2"
                                    dark
                                    @click="set_item(item)"
                            >
                                <v-icon>mdi-factory</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.fob_price="{ item }">
                            {{ item.fob_price | currency }}
                        </template>

                        <template v-slot:item.album="{ item }">
                            <template v-if="!!item['album']">
                                <v-layout v-if="!!item['album']['files']">
                                    <template v-for="file of item['album']['files']">
                                        <v-img :src="$store.state.backend_url + file['url']"
                                               :alt="file['name']"
                                               height="40"
                                               width="40">
                                        </v-img>
                                    </template>
                                </v-layout>
                            </template>
                        </template>

                    </v-data-table>

                    <div class="text-center">
                        <v-pagination v-model="page"
                                      circle
                                      :total-visible="7"
                                      :length="max_count"
                        ></v-pagination>
                    </div>
                </material-card>
            </v-flex>
        </v-layout>
        <v-dialog
                v-model="dialog"
                width="500"
        >

            <v-card>
                <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                >
                    {{ selected_item.department.name }} <span class="font-italic ml-2">[{{ selected_item.department.category }}]</span>
                </v-card-title>

                <v-card-text>
                    <h2 class="headline mb-5">
                        {{ selected_item.department.name }}
                    </h2>
                    <v-simple-table>
                        <tr>
                            <th>
                                <v-icon>mdi-tag-multiple</v-icon>
                            </th>
                            <td>
                                {{ selected_item.department.category }}
                            </td>
                        </tr>
                        <tr>
                            <th><v-icon>mdi-map-marker</v-icon></th>
                            <td>
                                {{ selected_item.department.address }}
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <v-icon>mdi-account-box</v-icon>
                            </th>
                            <td>
                                <template v-for="user of selected_item.department.users">
                                    <v-row class="ml-0 mr-0">{{user.last_name}} , {{user.first_name}}: {{ user.email }}  {{' - #'  + user.phone + ( (user.phone_extension)? 'EXT[' + user.phone_extension + ']' : '')}} {{' - #' + user.mobile }}</v-row>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <v-icon>mdi-account-supervisor</v-icon>
                            </th>
                            <td>
                                <v-row class="mx-0">
                                    <v-col class="pa-0 shrink">Sourcers:</v-col>
                                    <v-col class="pa-0 ml-1 mt-1">
                                        <template v-for="user of selected_item.department.sourcers">
                                            <v-row class="ml-0 mr-0">{{user.last_name}} , {{user.first_name}} : {{ user.email }} </v-row>
                                        </template>
                                    </v-col>
                                </v-row>
                            </td>
                        </tr>
                    </v-simple-table>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="dialog = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>
    import ProductUpload from '@/components/cpanel/ProductUpload'
    import moment from "moment";

    export default {
        name: "Products",
        data: () => ({
            title: "Products",
            products: [],
            page:1,
            offset:0,
            limit:50,
            max_count:0,
            filter:[],
            search_url:'',
            headers: [
                {
                    text: 'Actions',
                    value: 'actions',
                    custom: 'actions',
                    align: 'center'
                },
                {
                    text: 'Album',
                    value: 'album',
                    align: 'center',
                    custom: 'image'
                },
                {
                    text: 'Item Num',
                    value: 'item_number',
                    align: 'center'
                },
                {
                    text: 'WS Item Num',
                    value: 'erp_item_number',
                    align: 'center'
                },
                {
                    text: 'Created At',
                    value: 'created_at',
                    align: 'center'
                },
                {
                    text: 'Last Updated',
                    value: 'updated_at',
                    align: 'center'
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center'
                },
                {
                    text: 'FOB',
                    value: 'fob_price',
                    align: 'center'
                },
                {
                    text: 'Fact Details',
                    value: 'fact_details',
                    align: 'center'
                }
            ],
            loading:true,
            dialog:false,
            selected_item: {department:{}},
            factories: []
        }),
        created () {
            if(typeof this.$route.params.id !== 'undefined')
            {
                this.filter.factory = this.$route.params.id;
                this.filter_results(this.filter);
            } else {
                this.load_products();
            }
        },
        watch: {
            page(val) {
                this.load_products();
            },
        },
        methods: {
            set_item(item)
            {
               this.selected_item = item;
               this.dialog= true;
            },
            edit_product(id){
                // this.$router.push({path: '/c-panel/product/' + id});
                window.open('/c-panel/product/'+id, '_blank');

            },

            async load_products() {
                let offset = (this.page - 1) * this.limit;

                let res = await this.$hget('/sourcers/products/?limit=50&offset=' + offset + this.search_url).then( r => r.data);
                console.log(res);

                this.max_count = Math.ceil((res.count + 25) / this.limit);

                this.products = res.results;
                this.loading = false;

                if(!this.factories.length)
                {
                    this.$http.get('/sourcers/organisations/')
                        .then((response) => {
                            console.log(response.data);
                            this.loading = false;
                            this.factories = response.data.results
                        })
                        .catch((error) => {
                            console.log(error);
                            this.loading = false;
                        });
                }
            },
            showUploadModal() {
                // this.$modal.show( ProductUpload,
                //     {
                //         adaptive: true,
                //         scrollable: true,
                //         reset:true,
                //         height: 'auto',
                //         width: '80%',
                //         clickToClose: false
                //     },
                // )
                this.$modal.show( ProductUpload,
                    {
                        text: 'This text is passed as a property'
                    },
                    {
                        adaptive: true,
                        scrollable: true,
                        reset:true,
                        height: 'auto',
                        width: '80%',
                        clickToClose: false
                    },
                )
            },

            filter_results(filter){
                let search_url = '';
                for (let prop in filter) {
                    // skip loop if the property is from prototype
                    if(!filter.hasOwnProperty(prop)) continue;
                    search_url += '&'+prop+'='+filter[prop];
                }
                this.search_url = search_url;
                this.page = 1;
                this.load_products();
            },
            format_date(date)
            {
                return moment(String(date)).format('MM/YYYY')
            }
        }


    }
</script>

<style scoped>

</style>
